// Generated by Framer (a4439fa)

import { addFonts, cx, CycleVariantState, SVG, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["h0ep5HSX3"];

const serializationHash = "framer-5ga4f"

const variantClassNames = {h0ep5HSX3: "framer-v-170xwdd"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "h0ep5HSX3", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} className={cx(serializationHash, ...sharedStyleClassNames, "framer-170xwdd", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"h0ep5HSX3"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}}><SVG className={"framer-w4m0f9"} data-framer-name={"Template_Logo"} fill={"black"} intrinsicHeight={51} intrinsicWidth={50} layoutDependency={layoutDependency} layoutId={"lN41tySTF"} svg={"<svg width=\"50\" height=\"51\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\"><g clip-path=\"url(#a)\" fill=\"#fff\"><path d=\"M16.666 8.849c0 4.6 3.733 8.333 8.333 8.333h16.667a8.336 8.336 0 0 1-8.333-8.333m-.015-.016V.5H16.652v8.333h16.666Zm.016 33.347c0-4.599-3.734-8.332-8.334-8.332H8.334c4.6 0 8.333 3.733 8.333 8.333\"/><path d=\"M16.682 42.167V50.5h16.666v-8.333H16.682Z\"/><path d=\"M41.667 17.182a8.336 8.336 0 0 0-8.333 8.333V42.18c0-4.6 3.733-8.333 8.333-8.333m0-.029H50V17.152h-8.333v16.666Zm-33.333.031c4.6 0 8.333-3.734 8.333-8.334V8.85c0 4.6-3.733 8.333-8.333 8.333m-.001-.001H0v16.666h8.333V17.182Z\"/></g><defs><clipPath id=\"a\"><path fill=\"#fff\" transform=\"translate(0 .5)\" d=\"M0 0h50v50H0z\"/></clipPath></defs></svg>"} withExternalLayout/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-5ga4f.framer-4snxw9, .framer-5ga4f .framer-4snxw9 { display: block; }", ".framer-5ga4f.framer-170xwdd { height: 51px; overflow: visible; position: relative; width: 50px; }", ".framer-5ga4f .framer-w4m0f9 { aspect-ratio: 0.9803921568627451 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 51px); left: 0px; position: absolute; right: 0px; top: 0px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 51
 * @framerIntrinsicWidth 50
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerxQavREEdg: React.ComponentType<Props> = withCSS(Component, css, "framer-5ga4f") as typeof Component;
export default FramerxQavREEdg;

FramerxQavREEdg.displayName = "Logo DLS";

FramerxQavREEdg.defaultProps = {height: 51, width: 50};

addFonts(FramerxQavREEdg, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})